import React from 'react'
import Link from 'gatsby-link'
import 'semantic-ui-css/semantic.min.css';
import P5Component from '../../components/P5Component'
import './style.css'
import skull from '../../assets/images/skull.png'
import sketch from "../../sketches/00.js"
import navigateTo from 'gatsby-link'
import axiso from 'axios'
import axios from 'axios';
import video from '../../assets/death-vid.mp4'
import WordForm from '../../components/WordForm'

class WordGame extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        words: "",
        sketch: false
      }
      this.handleWord = this.handleWord.bind(this)
      this.submitWords = this.submitWords.bind(this)
  }

  
  postToAirTable(word){
    axios.post("https://api.airtable.com/v0/app1POYiufgjnmVCa/words?maxRecords=10&view=Grid%20view", {
    "fields": {
        "words": word
      }
    }, {
    headers: {"Authorization": `Bearer ${process.env.GATSBY_AT_KEY}`},
    }).then((response) => {
      console.log('RESPONSE', response)
      const words = response.data.fields.words
      this.setState({
        words,
        sketch: true
      }, () => {
      })
    
    })
        .then((responseData) => {
            // const words = responseData.records
            // this.setState({words}, () => {
            //     console.log('AFTER POST', this.state.words);
            // })
      })
  }

  handleWord(event) {
    this.setState({
      words: event.target.value
    })
  }

 submitWords(){
    let word = this.state.words
    this.postToAirTable(word)
    
 }

  render() {
      
      // if (this.props.sketchMount) {
      return (
        <div>    
             {/* <Link to ="/table-of-contents"> */}
            <div className="p5-container death-game">
              {this.state.sketch && <P5Component sketch = {sketch} sketchMount={this.props.sketchMount}/>}
            </div>
            <div className="meditation-back">
              <Link to='/table-of-contents'>Home</Link>
            </div>
            <video autoPlay muted loop id="myVideo">
                <source src={video} type="video/mp4"/>
            </video>
            {!this.state.sketch && <div className="word-form">
              <div className="options-back"></div>
              <div className="word-form-form">
                <h4>Has your opinion changed, what word are you thinking of now?</h4>
                <input value={this.state.words} name="word1" onChange={this.handleWord} />
                <div className="word-submit" onClick={this.submitWords}>Enter</div>
              </div>
            </div>}
                {/* <div className="hide-splash" onClick={this.props.hideSplash}>Enter</div> */}
              {/* </Link> */}
          </div>
    )
// }
    // else return null
  }

}

export default WordGame
